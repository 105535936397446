<template>
  <b-row class="center-aligning-row">
    <b-col cols="12" md="8" lg="5" v-if="!succeeded">
      <h2>{{ $t("resetPassword.sectionTitle") }}</h2>
      <confirm-reset-password-form @succeeded="onSucceeded" />
    </b-col>
    <b-col cols="12" md="8" lg="5" v-else>
      <h2>{{ $t("resetPassword.success") }}</h2>
      <div class="justify-content-center d-flex">
        <b-button to="/">{{ $t("general.logIn") }}</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ConfirmResetPasswordForm from "../components/forms/ConfirmResetPasswordForm.vue";
export default {
  name: "reset-password",
  components: {
    ConfirmResetPasswordForm,
  },
  data() {
    return {
      succeeded: false,
    };
  },
  methods: {
    onSucceeded() {
      this.succeeded = true;
    },
  },
};
</script>

<style>
</style>