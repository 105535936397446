var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newPasswordObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleConfirmResetPassword)}}},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('validation-provider',{attrs:{"rules":{
            required: true,
            min: 8,
            regex: _vm.PASSWORD_REGEX,
          },"name":"new_password1","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var failed = ref.failed;
return [_c('simple-form-input',{attrs:{"type":"password","label":_vm.$t('changePasswordForm.newPasswordLabel'),"hasError":failed,"helperText":errors[0],"autocomplete":"new-password"},model:{value:(_vm.new_password1),callback:function ($$v) {_vm.new_password1=$$v},expression:"new_password1"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"min:8|required|password:@new_password1","name":"new_password2","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var failed = ref.failed;
return [_c('simple-form-input',{attrs:{"type":"password","label":_vm.$t('changePasswordForm.newPassword2Label'),"hasError":failed,"helperText":errors[0],"autocomplete":"new-password"},model:{value:(_vm.new_password2),callback:function ($$v) {_vm.new_password2=$$v},expression:"new_password2"}})]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.isSettingNewPass}},[(_vm.isSettingNewPass)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("changePasswordForm.resetPassword"))+" ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }