<template>
  <validation-observer v-slot="{ handleSubmit }" ref="newPasswordObserver">
    <form @submit.prevent="handleSubmit(handleConfirmResetPassword)">
      <b-row cols="1">
        <b-col>
          <validation-provider
            :rules="{
              required: true,
              min: 8,
              regex: PASSWORD_REGEX,
            }"
            v-slot="{ errors, failed }"
            name="new_password1"
            mode="lazy"
          >
            <simple-form-input
              type="password"
              v-model="new_password1"
              :label="$t('changePasswordForm.newPasswordLabel')"
              :hasError="failed"
              :helperText="errors[0]"
              autocomplete="new-password"
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            rules="min:8|required|password:@new_password1"
            v-slot="{ errors, failed }"
            name="new_password2"
            mode="lazy"
          >
            <simple-form-input
              type="password"
              v-model="new_password2"
              :label="$t('changePasswordForm.newPassword2Label')"
              :hasError="failed"
              :helperText="errors[0]"
              autocomplete="new-password"
            />
          </validation-provider>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <b-button type="submit" :disabled="isSettingNewPass">
            <b-spinner small v-if="isSettingNewPass" />
            {{ $t("changePasswordForm.resetPassword") }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import MainAlert from "../alerts/MainAlert.vue";
import { PASSWORD_REGEX } from "../../utils/constants";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { mapActions } from "vuex";
export default {
  name: "confirm-reset-password-form",
  components: { SimpleFormInput },
  data() {
    return {
      new_password1: "",
      new_password2: "",
      isSettingNewPass: false,
      PASSWORD_REGEX,
    };
  },
  methods: {
    ...mapActions(["confirmResetPassword"]),
    handleConfirmResetPassword() {
      this.isSettingNewPass = true;
      this.confirmResetPassword({
        new_password1: this.new_password1,
        new_password2: this.new_password2,
        token: this.$route.params.token,
        uid: this.$route.params.uid,
      })
        .then(() => {
          this.$emit("succeeded");
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.$t("passwordResetSuccessful"),
            },
          });
        })
        .catch((err) => {
          this.status = "error";
          this.$refs.newPasswordObserver.setErrors(err.response.data);
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("passwordResetError"),
            },
          });
        })
        .finally(() => (this.isSettingNewPass = false));
    },
  },
};
</script>

<style>
</style>